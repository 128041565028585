import React, { FunctionComponent } from "react";

import AniLink from "gatsby-plugin-transition-link/AniLink";

const CookiePolicy: FunctionComponent = () => {
  return (
    <div>
      <div className="w-full">
        <div className="flex bg-white h-auto lg:h-52">
          <div className="flex pt-16 lg:items-center text-left px-8 md:px-28 lg:w-4/5">
            <div>
              <p className="text-3xl text-bytestrone-blue font-semibold md:text-4xl">
                Cookie Policy
              </p>
              <p className="mt-2 text-sm text-gray-900 md:text-base">
                This cookie policy (“Cookie Policy”) describes the types of
                cookies, pixels, local storage objects, and similar devices
                (collectively, &quot;cookies&quot; unless otherwise noted) we
                use on the Bytestrone website and our purposes for using them.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex bg-white">
        <div className="flex flex-col items-center text-left lg:text-left px-8 pb-12 md:px-28 lg:w-4/5 ">
          <div>
            <p className="mt-2 text-sm text-gray-500 md:text-base">
              If you have questions about this Cookie Policy, please contact us
              at{" "}
              <a className="text-blue-500" href="mailto:info@bytestrone.com">
                info@bytestrone.com
              </a>
              .
            </p>

            <p className="mt-2 text-sm text-gray-500 md:text-base">
              For more information about our privacy practices, please review
              our 
              <AniLink to="/privacy-policy" className="text-blue-500"> privacy policy</AniLink>.
            </p>
            <h2 className="py-6 text-black">WHAT IS A COOKIE?</h2>

            <p>
              A cookie is a small file that websites send to your computer or
              other Internet-connected device to uniquely identify your browser
              or to store information or settings in your browser. Unless you
              have adjusted your browser setting so that it will refuse cookies,
              our website will issue cookies as soon as you visit the website.
            </p>

            <h2 className="py-6 text-black">
              HOW TO CONTROL AND DELETE COOKIES?
            </h2>

            <p>
              You can control and/or delete cookies as you wish – for details,
              see{" "}
              <a className="text-blue-400" href="http://www.aboutcookies.org/">
                aboutcookies.org
              </a>
              . You can delete all cookies that are already on your computer and
              you can set most browsers to prevent them from being placed. If
              you do this, however, you may have to manually adjust some
              preferences every time you visit a site and some services and
              functionalities may not work.
            </p>

            <p>
              These cookie settings are usually found in the &apos;options&apos;
              or &apos;preferences&apos; menu of your internet browser. In order
              to understand these settings, the following links may be helpful.
              Otherwise you should use the &apos;Help&apos; option in your
              internet browser for more details.
            </p>

            <ol className="px-4 list-disc">
              <li>
                <a
                  className="text-blue-400"
                  href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cookie settings in Internet Explorer
                </a>
              </li>
              <li>
                <a
                  className="text-blue-400"
                  href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cookie settings in Firefox
                </a>
              </li>
              <li>
                <a
                  className="text-blue-400"
                  href="https://support.google.com/chrome/bin/answer.py?hl=en&amp;answer=95647"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cookie settings in Chrome
                </a>
              </li>
              <li>
                <a
                  className="text-blue-400"
                  href="https://support.apple.com/kb/HT1677"
                  target="_blank"
                  rel="noreferrer"
                >
                  Cookie settings in Safari
                </a>
              </li>
            </ol>
            <p>
              To opt-out of third-parties collecting any data regarding your
              interaction on our website, please refer to their websites for
              further information.
            </p>

            <h2 className="py-6 text-black">MORE INFORMATION ON COOKIES</h2>

            <p>
              To find out more about cookies, including how to see what cookies
              have been set and how to manage and delete them, visit{" "}
              <a
                className="text-blue-400"
                href="http://www.aboutcookies.org/"
                target="_blank"
                rel="noreferrer"
              >
                aboutcookies.org
              </a>{" "}
              or{" "}
              <a
                className="text-blue-400"
                href="http://www.allaboutcookies.org/"
                target="_blank"
                rel="noreferrer"
              >
                allaboutcookies.org
              </a>
              .
            </p>

            <h2 className="py-6 text-black">WHAT COOKIES DO WE USE?</h2>

            <p>Bytestrone may use the following cookies:</p>

            <p className="py-6">
              <strong>Types of Cookies</strong>
            </p>

            <p>
              <strong>1. First and third-party cookies:</strong> whether a
              cookie is &apos;first&apos; or &apos;third&apos; party refers to
              the domain placing the cookie. First-party cookies are those set
              by a Web site that is being visited by the user at the time.
            </p>

            <p>
              <strong>2. Third-party cookies</strong> are cookies that are set
              by a domain other than that of the Web site being visited by the
              user. If a user visits a Web site and another entity sets a cookie
              through that Web site, this would be a third-party cookie.
            </p>

            <p className="py-6">
              <strong>Duration of Cookies</strong>
            </p>

            <p>
              <strong>1. Session cookies:</strong> these cookies allow Web site
              operators to link the actions of a user during a browser session.
              A browser session starts when a user opens the browser window and
              finishes when they close the browser window. Session cookies are
              created temporarily. Once you close the browser, all session
              cookies are deleted.
            </p>

            <p>
              <strong>2. Persistent cookies:</strong> these cookies remain on a
              user’s device for the period of time specified in the cookie. They
              are activated each time that the user visits the Web site that
              created that particular cookie.
            </p>

            <p>
              We only use the types of cookies described above. We don’t use
              so-called ‘specialist cookies’. In addition, Bytestrone uses
              Google Analytics for the only purpose of obtaining the report of
              the activity they are provide.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
